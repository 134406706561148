const applicationId = 'db88df16-4e8f-4c62-afb6-cb959134184c';

export const environment = {
    production: true,
    debug: false,
    apiUrl: '/api',
    pdfUrl: 'https://api.prmtool.homij.nl/api',
    logo: '/assets/images/logo_homij.svg',
    applicationInsights: {
        instrumentationKey: 'd88cc4d4-5d9d-42ec-ad43-aa2c5e883bb7'
    },
    msAuth: {
        issuer: 'https://login.microsoftonline.com/common/v2.0',
        clientId: applicationId,
        redirectUri: `${window.location.origin}/msal-auth`,
        scopes: [`${applicationId}/.default`],
        redirectToExternalEndSessionEndpointAfterLogout: false,
        redirectRoute: {
            afterAuthentication: ['/'],
            noAuthentication: ['/no-access'],
            failedAuthentication: ['/error']
        },
        // Use LocalStorage to allow same authentication across multiple browser tabs and persistence of tokens
        sessionStorageDriver: localStorage
    },
    features: {
        accessibleSteps: {
            enabled: true,
            showSetToDefaultButton: false // Useful for testing / debugging
        },
        apiProjectCreation: {
            enabled: false
        }
    }
};
